<template>
    <v-row class="row--x-small" align="center">
        <v-col cols="9">
            <v-text-field v-model="postcode" @click="search" placeholder="우편번호" readonly v-bind="$attrs" />
        </v-col>
        <v-col cols="3">
            <v-btn tile color="primary lighten-4" class="w-100 v-btn--input" @click="search">검색</v-btn>
        </v-col>
        <v-col cols="12">
            <v-text-field v-model="address1" @click="search" placeholder="기본주소" readonly v-bind="$attrs" />
        </v-col>
        <v-col cols="12">
            <v-text-field v-model="address2" placeholder="상세주소" v-bind="$attrs" />
        </v-col>
        <daum-postcode ref="daum-postcode" @change="processAddressData" />
    </v-row>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
export default {
    components: {
        DaumPostcode,
    },
    props: {
        value: { type: Object, default: null }, // user
    },
    data: () => ({
        postcode: null,
        address1: null,
        address2: null,
    }),
    computed: {},
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        postcode() {
            this.emit();
        },
        address1() {
            this.emit();
        },
        address2() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.postcode = this.value?.postcode || null;
            this.address1 = this.value?.address1 || null;
            this.address2 = this.value?.address2 || null;
        },
        emit() {
            const { postcode, address1, address2 } = this;
            this.$emit("input", { ...this.value, postcode, address1, address2 });
        },
        search() {
            this.$refs["daum-postcode"]?.open?.();
        },
        processAddressData({ postcode, address }) {
            this.postcode = postcode;
            this.address1 = address;
        },
    },
};
</script>

<style></style>
