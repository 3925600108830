<template>
    <v-select v-model="selected" v-bind="{ ...$attrs, items }" @input="sync" />
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: { type: Number, default: 1990 },
    },
    data() {
        return {
            selected: null,
        };
    },
    computed: {
        items() {
            const years = [];
            let year = dayjs().year();
            do {
                years.push(year);
                year -= 1;
            } while (year >= 1900);
            return years.map((value) => ({ text: value + "년", value }));
        },
    },
    methods: {
        init() {
            this.selected = this.value;
        },
        sync() {
            this.$emit("input", this.selected);
        },
    },
    watch: {
        value() {
            if (this.value !== this.selected) this.selected = this.value;
        },
        selected() {
            if (this.selected !== this.value) this.sync();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
