<template>
    <v-select v-model="selected" v-bind="{ ...$attrs, items }" @input="sync" />
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: { type: Number, default: 1 },
        year: { type: Number, default: 1990 },
        month: { type: Number, default: 1 },
    },
    data() {
        return {
            selected: null,
        };
    },
    computed: {
        items() {
            const days = [];
            if (this.year && this.month) {
                let day = 1;
                const endDay = dayjs(`${this.year}-${String(this.month).padStart(2, 0)}-01`)
                    .endOf("month")
                    .date();
                do {
                    days.push(day);
                    day += 1;
                } while (day <= endDay);
            }
            return days.map((value) => ({ text: value + "일", value }));
        },
    },
    methods: {
        init() {
            this.selected = this.value;
        },
        sync() {
            this.$emit("input", this.selected);
        },
    },
    watch: {
        value() {
            if (this.value !== this.selected) this.selected = this.value;
        },
        selected() {
            if (this.selected !== this.value) this.sync();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
