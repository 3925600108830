<template>
    <v-row class="row--x-small" align="center">
        <v-col>
            <v-text-field v-model="emailUser" name="emailUser" v-bind="$attrs" />
        </v-col>
        <v-col cols="auto">@</v-col>
        <v-col>
            <v-text-field v-model="emailHost" name="emailHost" v-bind="$attrs" />
        </v-col>
        <v-col cols="12" sm="">
            <v-select v-model="emailSelect" name="emailSelect" v-bind="{ ...$attrs, items }" @change="emailHost = emailSelect" />
        </v-col>
    </v-row>
</template>

<script>
const items = [{ text: "직접입력", value: null }].concat(["naver.com", "hanmail.net", "gmail.com"].map((value) => ({ text: value, value })));
export default {
    props: {
        value: { type: String, default: null }, //email
    },
    data: () => ({
        items,

        emailUser: null,
        emailHost: null,
        emailSelect: null,
    }),
    computed: {
        email() {
            if (this.emailUser && this.emailHost) return `${this.emailUser}@${this.emailHost}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        emailUser() {
            this.emit();
        },
        emailHost() {
            this.emit();
        },
    },
    methods: {
        sync() {
            [this.emailUser = null, this.emailHost = null] = this.value?.split?.("@") || [];
            this.emailSelect = items.find(({ value }) => value == this.emailHost)?.value || null;
        },
        emit() {
            this.$emit("input", this.email);
        },
    },
};
</script>

<style></style>
