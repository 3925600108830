<template>
    <v-row>
        <v-col> <v-date-select-y v-model="year" v-bind="$attrs" /> </v-col>
        <v-col> <v-date-select-m v-model="month" v-bind="$attrs" /> </v-col>
        <v-col> <v-date-select-d v-model="day" v-bind="{ ...$attrs, year, month }" /> </v-col>
    </v-row>
</template>

<script>
import dayjs from "dayjs";
import VDateSelectY from "./v-date-select-y.vue";
import VDateSelectM from "./v-date-select-m.vue";
import VDateSelectD from "./v-date-select-d.vue";
export default {
    components: {
        VDateSelectY,
        VDateSelectM,
        VDateSelectD,
    },
    props: {
        value: { type: String, default: "1990-01-01" },
    },
    data: () => ({
        year: 1990,
        month: 1,
        day: 1,
    }),
    computed: {
        date() {
            return `${this.year}-${String(this.month).padStart(2, 0)}-${String(this.day).padStart(2, 0)}`;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.value !== this.date) this.sync();
        },
        date() {
            if (this.date !== this.value) this.emit();
        },
    },
    methods: {
        sync() {
            [this.year, this.month, this.day] = this.value.split("-").map((val) => +val);
        },
        emit() {
            this.$emit("input", this.date);
        },
    },
};
</script>

<style></style>
