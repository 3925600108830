<template>
    <v-row class="row--x-small" align="center">
        <v-col>
            <v-text-field v-model="phone1" v-bind="{ ...$attrs, readonly }" />
        </v-col>
        <v-col cols="auto">-</v-col>
        <v-col>
            <v-text-field v-model="phone2" v-bind="{ ...$attrs, readonly }" />
        </v-col>
        <v-col cols="auto">-</v-col>
        <v-col>
            <v-text-field v-model="phone3" v-bind="{ ...$attrs, readonly }" />
        </v-col>
        <template v-if="hasCertification">
            <v-col cols="12" sm="auto">
                <v-btn @click="certify" tile color="primary" class="w-100 w-sm-120px v-btn--input">본인인증</v-btn>
            </v-col>
            <kcp-cert ref="kcp-cert" @input="processCertifiedData" />
        </template>
    </v-row>
</template>

<script>
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
export default {
    components: {
        KcpCert,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // user
        hasCertification: { type: Boolean, default: false },
    },
    data: () => ({
        phone1: "010",
        phone2: null,
        phone3: null,
        _certification: null,
    }),
    computed: {
        readonly() {
            return this.hasCertification;
        },
        phone() {
            const { phone1, phone2, phone3 } = this;
            if (!!phone1 && !!phone2 && !!phone3) return `${phone1}-${phone2}-${phone3}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.phone != this.value?.phone) this.sync();
        },
        phone() {
            this.emit();
        },
        _certification() {
            this.emit();
        },
    },
    methods: {
        sync() {
            [this.phone1, this.phone2, this.phone3] = this.value?.phone?.split?.("-") || ["010", null, null];
        },
        emit() {
            const { phone, _certification } = this;
            this.$emit("input", { ...this.value, phone, _certification });
        },
        certify() {
            this.$refs["kcp-cert"]?.auth?.();
        },
        processCertifiedData(payload) {
            [this.phone1, this.phone2, this.phone3] = payload?.phone?.phoneNumberFormat()?.split?.("-");
            this._certification = payload._certification;
        },
    },
};
</script>
